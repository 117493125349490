// @ts-nocheck
class gaFunctions {
  debugMode: 0 | 1;
  param: IParams;
  eventParams: IEventParams;
  sendQueueItemsURL: string[];
  measurementId: string;
  lastTime: number;
  firstClickfunction:any;
  hasBeenInitialized: boolean;
  timers: {
    print: number;
    elevationDialog: number;
    singleElevation: {
      name: string;
      time: number;
    };
    interiorDialog: number;
    singleInterior: {
      name: string;
      time: number;
    };
    notes: number;
    measure: number;
    customFurniture: number;
    customFurnitureRoom: {
      name: string;
      time: number;
    };
    compareDialog: number;
    comparePlans: {
      plan1: string;
      plan2: string;
      time: number;
    };
    viewFloorPlan: {
      name: string;
      time: number;
    };
  };

  constructor() {
    this.debugMode = process.env.NODE_ENV === 'development' ? 1 : 0;
    this.hasBeenInitialized = false;
    this.lastTime = 0;

    this.timers = {
      print: 0,
      elevationDialog: 0,
      singleElevation: {
        name: '',
        time: 0,
      },
      interiorDialog: 0,
      singleInterior: {
        name: '',
        time: 0,
      },
      notes: 0,
      measure: 0,
      customFurniture: 0,
      customFurnitureRoom: {
        name: '',
        time: 0,
      },
      compareDialog: 0,
      comparePlans: {
        plan1: '',
        plan2: '',
        time: 0,
      },
      viewFloorPlan: {
        name: '',
        time: 0,
      },
    };
    
    this.measurementId = 'G-WBTE88TFQ6';

    this.param = {
      /* end point for GA, with credits */
      hitString: 'https://www.google-analytics.com/mp/collect?',
      /* focus 360 user id */
      userId: '',
      sessionId: '',
    };

    this.eventParams = {
      /* non-required, focus user id */
      user_id: '',
      /* GA required, typically uuidv4 */
      client_id: '',
      /* GA required */
      non_personalized_ads: false,
      /* ga user properties, used to store persistent info such as Custom Properties, sent with each hit */
      user_properties: {},
      /* debug var */
    };

    this.sendQueueItemsURL = [];
  }

  private uuidv4(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  }

  /* client Id is a google tracking id, saved in the users browser */
  private setClientId(newClientId: string) {
    this.eventParams.client_id = newClientId;
    localStorage.setItem('f360ga4id', newClientId);
  }

  private onTimerCheckQue() {
    if (this.sendQueueItemsURL.length > 0) {
      fetch(this.sendQueueItemsURL[0], { method: 'POST' });
      this.sendQueueItemsURL.shift();
    }
  }

  public newSession() {
    this.param.sessionId = Math.floor(new Date().getTime() / 1000).toString();
  }

  public initBasicTracking(app_id: AppID, app_env: string) {
    
    /* set init flag */
    if ( this.param.sessionId === ''){
      this.newSession();
    }

    this.hasBeenInitialized = true
    /* append api secret to the URL, this determines what account it is tracked to */
    this.param.hitString += 'measurement_id=' + this.measurementId;

    if ( this.param.sessionId === '' ) {
      this.newSession();
    }

    /* init user properties object */
    this.eventParams.user_properties = {
      /* set tracking code version */
      gav: '2.2',
      /* set app_id */
      app_id,
      /* set app_env */
      app_env:app_env,
    };

    /* check local storage for previous focus ga4 tracking */
    const client_id = localStorage.getItem('f360ga4id') ?? this.uuidv4();

    /* set this client id on data object */
    this.eventParams.client_id = client_id;

    /* initially set user ID to client_id, unless a "login" happens */
    this.setClientId(client_id);

    /* init queue array */
    this.sendQueueItemsURL = [];

    /* use set interval to send hits from queue */
    setInterval(() => {
      this.onTimerCheckQue();
    }, 200);

    /* set lastTime starting time for time tracking */
    this.lastTime = Date.now();

    /* record first click.  Many sessions may not have a first click, is shows real engagement */
    this.firstClickfunction = document.addEventListener('click', this.firstClick);

    /* add window error tracking */
    // window.addEventListener('error', this.onErrorReport);
  }

  // public onErrorReport(e: ErrorEvent) {
  //   gaFunction.sendEvent('window_error', {
  //     module_id: 'na',
  //     error_message: e.message,
  //     error_file: e.filename,
  //     error_line: e.lineno,
  //     error_error: e.error,
  //   });
  // }

  public setClientInformation(focusClientId: string, clientName: string, clientDivision: string) {
    /* ensure undefined or null is never set */
    if (focusClientId) {
      this.eventParams.user_properties.client_id = focusClientId;
    }
    if (clientName) {
      this.eventParams.user_properties.client_name = clientName;
    }
    if (clientDivision) {
      this.eventParams.user_properties.client_division = clientDivision;
    }
  }

  public setCommunityInformation(communityId: string, communityName: string) {
    /* ensure "undefined" or null is never set */
    if (communityId) {
      this.eventParams.user_properties.community_id = communityId;
    }
    if (communityName) {
      this.eventParams.user_properties.community_name = communityName;
    }
  }

  // public resetUser() {
  //   this.setClientId(this.uuidv4());
  //   this.newSession();
  // }

  public newUser() {
    this.setClientId(this.uuidv4());
    this.newSession();
  }

  /* user Id is a focus user identifier. should be from log in */
  // public setUserId(newUserId: string, method: string = 'na') {
  //   this.eventParams.user_id = newUserId;
  //   this.sendEvent('login', { method: method });
  // }

  // public clearUserId(newUserId: string) {
  //   this.eventParams.user_id = newUserId;
  //   this.sendEvent('logout', {});
  // }

  public setAppId(app_id: AppID) {
    this.eventParams.user_properties.app_id = app_id;
  }

  //******************** EVENTS *************************/
  public firstClick(e: Event) {
    document.removeEventListener('click', this.firstClickfunction );
    (e.currentTarget as Document).removeEventListener('click', gaFunction.firstClick);
    gaFunction.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action: 'First Click'    ,
      label:''  
    });
  }
  
  public dsViewDesignerScene( planName:string ){
    this.eventParams.user_properties.plan_name = planName;

    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action:"Open Scene",
      label: planName
    });
    this.sendPageview(planName);
  }

  public dsSceneOpenedFromEmail( planName:string ){
    this.eventParams.user_properties.plan_name = planName;
    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action:"Scene opened from email link",
      label: planName
    });
  }

  public dsViewDesignerSelector( planName:string ){
    this.eventParams.user_properties.plan_name = planName;
    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action:"View Selector Page",
      label: planName
    });
    }

    public dsSendStandardEvent( Cat:string, Action:string, Label:string ){
        this.sendEvent('designer_event', {
            feature:'designer',
            module_id: 'ds',
            category:Cat,
            action:Action,
            label:Label
        });
    }
  
    public dsSendStandardEventAndValue( Cat:string, Action:string, Label:string, Value:number ){
      this.sendEvent('designer_event', {
          feature:'designer',
          module_id: 'ds',
          category:Cat,
          action:Action,
          label:Label,
          value:Value
      });
  }
    public dsSelectCamera( CameraName:string ){
        this.sendEvent('designer_event', {
            feature:'designer',
            module_id: 'ds',
            category:'App',
            action:"Camera by Sidebar",
            label: CameraName
        });
    }

  public dsScenePrint( planName:string ){
    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action:"Scene opened from email link",
      label: planName
    });
  }
  public dsRecordSelection( cat:string, action:string, label:string, value:number ){
    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:cat,
      action:action,
      label:label,
      value:value
    });
    }

  public dsOpenOptionBar( ){
    this.sendEvent('designer_event', {
      feature:'designer',
      module_id: 'ds',
      category:'App',
      action: 'Option Bar',
      label: 'Open'
    });
  }
  

  public dsSetSceneTitle( sceneTitle:string ){
    this.eventParams.user_properties.scene_title = sceneTitle;
  }
  
  /***************************  FLOOR PLAN *****************************/
  // VIEW FLOOR PLAN
  public fpViewFloorPlan(planName: string) {
    this.eventParams.user_properties.plan_name = planName;
    this.sendEvent('view_floor_plan', {
      module_id: 'fp',
      label: 'View Floor Plan',
      feature: 'floor_plan',
      value: planName,
    });

    this.timers.viewFloorPlan = {
      name: planName,
      time: Date.now(),
    };
  }

  // FLIP
  public fpFlipPlan() {
    this.sendEvent('flip', { module_id: 'fp', label: 'Flip Plan', feature: 'flip' });
  }

  // FIND ON SITE MAP
  public fpFindOnSiteMap(planName: string) {
    this.sendEvent('find_on_site_map', {
      module_id: 'fp',
      label: 'Find Floor Plan On Site Map',
      feature: 'find_on_site_map',
      value: planName,
    });
  }

  // FLOORS
  public fpViewFloor(selectedFloor: string) {
    this.sendEvent('view_floor', {
      module_id: 'fp',
      label: 'View Floor',
      feature: 'floor',
      value: selectedFloor === 'All' ? `All Floors` : `Floor ${selectedFloor}`,
    });
  }

  // INTERIORS
  public fpOpenInteriorDialog() {
    this.timers.interiorDialog = Date.now();
    this.sendEvent('interior_dialog_open', {
      module_id: 'fp',
      label: 'Open Interior Dialog',
      feature: 'interior',
    });
  }

  public fpViewInterior(interiorName: string, isFirst: boolean) {
    this.sendEvent('view_interior', {
      module_id: 'fp',
      label: 'View Interior',
      feature: 'interior',
      value: interiorName,
    });

    if (isFirst) {
      this.sendEvent('view_interior_first', {
        module_id: 'fp',
        label: 'First Interior View',
        feature: 'interior',
        value: interiorName,
      });
    }

    if (this.timers.singleInterior.time !== 0) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.singleInterior.time);
      this.sendEvent('view_interior_time', {
        module_id: 'fp',
        label: 'Time Spent On Single Interior',
        feature: 'interior',
        value: this.timers.singleInterior.name,
        time_format,
        time_ms,
      });
    }

    this.timers.singleInterior = {
      name: interiorName,
      time: Date.now(),
    };
  }

  public fpCloseInteriorDialog() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.interiorDialog);

    this.sendEvent('interior_dialog_time', {
      module_id: 'fp',
      label: 'Time Spent On Interior Dialog',
      feature: 'interior',
      time_format,
      time_ms,
    });

    if (this.timers.singleInterior.time !== 0) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.singleInterior.time);
      this.sendEvent('view_interior_time', {
        module_id: 'fp',
        label: 'Time Spent On Single Interior',
        feature: 'interior',
        value: this.timers.singleInterior.name,
        time_format,
        time_ms,
      });
    }

    this.timers.singleInterior = {
      name: '',
      time: 0,
    };
  }

  // public fpOpenMPVRDialog() {
  //   this.dialogTimer = Date.now();
  // }
  // public fpCloseMPVRDialog() {
  //   this.sendTiming('dialog', 'Viewing Multipoint VR', Date.now() - this.dialogTimer);
  // }
  // public fpOpenPivotVRDialog() {
  //   this.dialogTimer = Date.now();
  // }
  // public fpClosePivotVRDialog() {
  //   this.sendTiming('dialog', 'Viewing Pivot VR', Date.now() - this.dialogTimer);
  // }
  // public fpOpenLiveFlexDialog() {
  //   this.dialogTimer = Date.now();
  // }
  // public fpCloseLiveFlexDialog() {
  //   this_close.sendTiming('dialog', 'Viewing Live Flex', Date.now() - this.dialogTimer);
  // }

  // NOTES
  public fpNotesOpen() {
    this.timers.notes = Date.now();
    this.sendEvent('notes_open', {
      module_id: 'fp',
      label: 'Open Notes Editor',
      feature: 'notes',
    });
  }

  public fpNotesClose() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.notes);
    this.sendEvent('notes_time', {
      module_id: 'fp',
      label: 'Time Spent On Notes Editor',
      feature: 'notes',
      time_format,
      time_ms,
    });
    this.timers.notes = 0;
  }

  public fpNotesAdd() {
    this.sendEvent('notes_add', {
      module_id: 'fp',
      label: 'Add Note',
      feature: 'notes',
    });
  }

  public fpNotesDelete() {
    this.sendEvent('notes_delete', {
      module_id: 'fp',
      label: 'Delete Note',
      feature: 'notes',
    });
  }

  // OPTIONS
  public fpSelectOption(optionName: string) {
    this.sendEvent('select_option', {
      module_id: 'fp',
      label: 'Select Option',
      feature: 'option',
      value: optionName,
    });
  }

  // LABELS
  public fpLabels(show: boolean) {
    if (show) {
      this.sendEvent('show_labels', {
        module_id: 'fp',
        label: 'Show Room Labels',
        feature: 'labels',
      });
    } else {
      this.sendEvent('hide_labels', {
        module_id: 'fp',
        label: 'Hide Room Labels',
        feature: 'labels',
      });
    }
  }

  // FURNITURE
  public fpAutoFill(room: string) {
    this.sendEvent('auto_fill_furn', {
      module_id: 'fp',
      label: 'Auto Fill Furniture',
      feature: 'furniture',
      value: room,
    });
  }
  public fpCustomFurnitureStart() {
    this.timers.customFurniture = Date.now();
    this.sendEvent('custom_furn_start', {
      module_id: 'fp',
      label: 'Start Custom Furniture',
      feature: 'furniture',
    });
  }
  public fpCustomFurnitureSelectRoom(room: string) {
    if (this.timers.customFurnitureRoom.name && this.timers.customFurnitureRoom.time) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.customFurnitureRoom.time);

      this.sendEvent('custom_furn_room_time', {
        module_id: 'fp',
        label: 'Time Spent On Custom Furniture Room',
        feature: 'furniture',
        value: this.timers.customFurnitureRoom.name,
        time_format,
        time_ms,
      });
    }

    this.sendEvent('custom_furn_select_room', {
      module_id: 'fp',
      label: 'Select Room for Custom Furniture',
      feature: 'furniture',
      value: room,
    });

    this.timers.customFurnitureRoom = {
      name: room,
      time: Date.now(),
    };
  }
  public fpPlaceFurniture() {
    this.sendEvent('place_furniture', {
      module_id: 'fp',
      label: 'Place Furniture',
      feature: 'furniture',
    });
  }
  public fpCustomFurnitureEnd() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.customFurniture);
    // send custom furniture time event and reset timer
    this.sendEvent('custom_furn_time', {
      module_id: 'fp',
      label: 'Time Spent On Custom Furniture',
      feature: 'furniture',
      time_format,
      time_ms,
    });

    this.timers.customFurniture = 0;

    // send custom furniture room time event and reset timer
    if (this.timers.customFurnitureRoom.name && this.timers.customFurnitureRoom.time) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.customFurnitureRoom.time);

      this.sendEvent('custom_furn_room_time', {
        module_id: 'fp',
        label: 'Time Spent On Custom Furniture Room',
        feature: 'furniture',
        value: this.timers.customFurnitureRoom.name,
        time_format,
        time_ms,
      });
    }
    this.timers.customFurnitureRoom = {
      name: '',
      time: 0,
    };
  }

  // MEASURE
  public fpMeasureStart() {
    this.timers.measure = Date.now();
    this.sendEvent('measure_start', {
      module_id: 'fp',
      label: 'Start Measuring',
      feature: 'measure',
    });
  }
  public fpMeasureEnd() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.measure);

    this.sendEvent('measure_time', {
      module_id: 'fp',
      label: 'Time Spent Measuring',
      feature: 'measure',
      time_format,
      time_ms,
    });

    this.timers.measure = 0;
  }

  // COMPARE
  public fpOpenCompareDialog() {
    this.timers.compareDialog = Date.now();
    this.sendEvent('compare_dialog_open', {
      module_id: 'fp',
      label: 'Open Compare Dialog',
      feature: 'compare',
    });
  }
  public fpCloseCompareDialog() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.compareDialog);
    // send compare dialog time event and reset timer
    this.sendEvent('compare_dialog_time', {
      module_id: 'fp',
      label: 'Time Spent On Compare Dialog',
      feature: 'compare',
      time_format,
      time_ms,
    });

    this.timers.compareDialog = 0;

    // send compare room time event and reset timer
    if (
      this.timers.comparePlans.plan1 &&
      this.timers.comparePlans.plan2 &&
      this.timers.comparePlans.time
    ) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.comparePlans.time);

      this.sendEvent('compare_plans_time', {
        module_id: 'fp',
        label: 'Time Spent Comparing Plans',
        feature: 'compare',
        value: this.timers.comparePlans.plan1,
        event_plan_name: this.timers.comparePlans.plan2,
        time_format,
        time_ms,
      });

      this.timers.comparePlans = {
        plan1: '',
        plan2: '',
        time: 0,
      };
    }
  }
  public fpComparePlans(plan1: string, plan2: string) {
    if (
      this.timers.comparePlans.plan1 &&
      this.timers.comparePlans.plan2 &&
      this.timers.comparePlans.time
    ) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.comparePlans.time);

      this.sendEvent('compare_plans_time', {
        module_id: 'fp',
        label: 'Time Spent Comparing Plans',
        feature: 'compare',
        value: this.timers.comparePlans.plan1,
        event_plan_name: this.timers.comparePlans.plan2,
        time_format,
        time_ms,
      });
    }

    this.timers.comparePlans = {
      plan1,
      plan2,
      time: Date.now(),
    };
    this.sendEvent('compare_plans', {
      module_id: 'fp',
      label: 'Compare Plans',
      feature: 'compare',
      value: plan1,
      event_plan_name: plan2,
    });
  }

  // VIEW ELEVATION ON FP
  public fpViewElevationOnFloorPlan(elevationStyle: string) {
    this.sendEvent('view_elevation_on_plan', {
      module_id: 'fp',
      label: 'View Elevation On Floor Plan',
      feature: 'elevation',
      value: elevationStyle,
    });
  }

  public fpViewFloorPlanTime() {
    const { time_ms, time_format } = this.getTimerValue(this.timers.viewFloorPlan.time);

    this.sendEvent('view_floor_plan_time', {
      module_id: 'fp',
      label: 'Time Spent Viewing Floor Plan',
      feature: 'floor_plan',
      value: this.timers.viewFloorPlan.name,
      time_format,
      time_ms,
    });

    this.timers.viewFloorPlan = {
      name: '',
      time: 0,
    };
  }

  /***************************  SITE MAP *****************************/
  public spSelectLot(lotTitle: string) {
    this.sendEvent('select_lot', {
      module_id: 'sp',
      feature: 'lot',
      label: 'Select Lot',
      value: lotTitle,
    });
  }

  public spSelectPlanFromLot(lotTitle: string, planName: string, elevationName: string) {
    this.eventParams.user_properties.plan_name = planName;
    this.sendEvent('select_elevation_from_lot', {
      module_id: 'sp',
      label: 'Select Elevation From Lot',
      feature: 'lot',
      value: elevationName,
      event_plan_name: planName,
      lot_title: lotTitle,
    });
  }

  public spShowDetails() {
    this.sendEvent('show_details', { module_id: 'sp', label: 'Show Details', feature: 'details' });
  }
  public spShowDimensions() {
    this.sendEvent('show_dimensions', {
      module_id: 'sp',
      label: 'Show Dimensions',
      feature: 'dimensions',
    });
  }

  public spTerrain(show: boolean) {
    this.sendEvent(show ? 'show_terrain' : 'hide_terrain', {
      module_id: 'sp',
      label: show ? 'Show Terrain' : 'Hide Terrain',
      feature: 'terrain',
    });
  }

  /***************************  VICINITY MAP *****************************/
  public vmSelectPOIMarker(poiCategoryTitle: string, poiTitle: string) {
    this.sendEvent('select_marker', {
      module_id: 'vm',
      label: 'Select Marker',
      feature: 'marker',
      value: poiTitle,
      category: poiCategoryTitle,
    });
  }

  public vmSelectPOIMenu(poiCategoryTitle: string, poiTitle: string) {
    this.sendEvent('select_poi', {
      module_id: 'vm',
      label: 'Select Point of Interest',
      feature: 'poi',
      value: poiTitle,
      category: poiCategoryTitle,
    });
  }

  public vmViewPOIDetails(poiCategoryTitle: string, poiTitle: string) {
    this.sendEvent('view_poi_details', {
      module_id: 'vm',
      label: 'View Point of Interest Details',
      feature: 'details',
      value: poiTitle,
      category: poiCategoryTitle,
    });
  }

  public vmViewCategoryMenu(poiCategoryTitle: string) {
    this.sendEvent('view_category_menu', {
      module_id: 'vm',
      label: 'View Category Menu',
      feature: 'category',
      value: poiCategoryTitle,
    });
  }

  public vmMapIt(input: string) {
    this.sendEvent('map_it', {
      module_id: 'vm',
      label: 'Map It',
      feature: 'map_it',
      value: input,
    });
  }

  public vmViewSendPOI(poiCategoryTitle: string, poiTitle: string) {
    this.sendEvent('view_send_poi', {
      module_id: 'vm',
      label: 'View Send POI',
      feature: 'send_poi',
      value: poiTitle,
      category: poiCategoryTitle,
    });
  }

  public vmSendPOI(type: 'email' | 'sms', poiCategoryTitle: string, poiTitle: string) {
    if (type === 'sms') {
      this.sendEvent('send_poi_sms', {
        module_id: 'vm',
        label: 'Send Point of Interest SMS',
        feature: 'sms',
        value: poiTitle,
        category: poiCategoryTitle,
      });
    } else {
      this.sendEvent('send_poi_email', {
        module_id: 'vm',
        label: 'Send Point of Interest Email',
        feature: 'email',
        value: poiTitle,
        category: poiCategoryTitle,
      });
    }
  }

  public vmViewSiteMap() {
    this.sendEvent('view_site_map_from_vm', {
      module_id: 'vm',
      label: 'View Site Map From Vicinity Map',
      feature: 'site_map_overlay',
    });
  }

  /***************************  PLAN BROWSER *****************************/
  public pbSelectPlan(planName: string) {
    this.eventParams.user_properties['plan_name'] = planName;
    this.sendEvent('select_plan', {
      module_id: 'pb',
      label: 'Select Plan',
      feature: 'floor_plan',
      value: planName,
    });
  }

  /***************************  GENERIC *****************************/
  // RESET
  public reset(module_id: 'fp' | 'sp' | 'vm' | 'pb') {
    const label =
      module_id === 'fp'
        ? 'Reset Floor Plan'
        : module_id === 'sp'
        ? 'Reset Site Plan'
        : module_id === 'vm'
        ? 'Reset Vicinity Map'
        : module_id === 'pb'
        ? 'Reset Plan Browser'
        : 'Reset';
    this.sendEvent('reset', { module_id, label, feature: 'reset' });
  }

  // ELEVATIONS
  public openElevationDialog(module_id: 'fp' | 'sp') {
    this.timers.elevationDialog = Date.now();
    this.sendEvent('elevation_dialog_open', {
      module_id,
      label: 'Open Elevation Dialog',
      feature: 'elevation',
    });
  }

  public viewElevation(module_id: 'fp' | 'sp', elevationStyle: string, isFirst: boolean) {
    this.sendEvent('view_elevation', {
      module_id,
      label: 'View Elevation',
      feature: 'elevation',
      value: elevationStyle,
    });

    if (isFirst) {
      this.sendEvent('view_elevation_first', {
        module_id,
        label: 'First Elevation View',
        feature: 'elevation',
        value: elevationStyle,
      });
    }

    if (this.timers.singleElevation.time !== 0) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.singleElevation.time);

      this.sendEvent('view_elevation_time', {
        module_id,
        label: 'Time Spent On Single Elevation',
        feature: 'elevation',
        value: this.timers.singleElevation.name,
        time_format,
        time_ms,
      });
    }

    this.timers.singleElevation = {
      name: elevationStyle,
      time: Date.now(),
    };
  }

  public closeElevationDialog(module_id: 'fp' | 'sp') {
    const { time_ms, time_format } = this.getTimerValue(this.timers.elevationDialog);
    this.sendEvent('elevation_dialog_time', {
      module_id,
      label: 'Time Spent On Elevation Dialog',
      feature: 'elevation',
      time_format,
      time_ms,
    });

    if (this.timers.singleElevation.time !== 0) {
      const { time_ms, time_format } = this.getTimerValue(this.timers.singleElevation.time);

      this.sendEvent('view_elevation_time', {
        module_id,
        label: 'Time Spent On Single Elevation',
        feature: 'elevation',
        value: this.timers.singleElevation.name,
        time_format,
        time_ms,
      });
    }

    this.timers.singleElevation = {
      name: '',
      time: 0,
    };
  }

  // PRINT
  public printOpen(module_id: 'fp' | 'sp') {
    this.timers.print = Date.now();
    this.sendEvent('print_open', { module_id, label: 'Open Print', feature: 'print' });
  }

  public printCancel(module_id: 'fp' | 'sp') {
    const { time_ms, time_format } = this.getTimerValue(this.timers.print);

    this.sendEvent('print_time', {
      module_id,
      label: 'Time Spent On Print',
      feature: 'print',
      time_format,
      time_ms,
    });
  }

  public printStart<T extends keyof PrintStartParams>(module_id: T, params: PrintStartParams[T]) {
    this.sendEvent('print_start', {
      module_id,
      label: 'Print',
      feature: 'print',
    });
  }

  // FILTER
  public selectFilter(
    module_id: 'sp' | 'pb',
    selectedFilterCategoryName: string,
    selectedFilterName: string
  ) {
    this.sendEvent('select_filter', {
      module_id,
      label: 'Select Filter',
      feature: 'filter',
      value: selectedFilterName,
      category: selectedFilterCategoryName,
    });
  }

  // EMAIL
  public sendEmail(module_id: 'sp' | 'fp') {
    this.sendEvent('email', {
      module_id,
      label: 'Email',
      feature: 'email',
    });
  }

  // SESSION
  public startSession() {
    this.param.sessionId = Math.floor(new Date().getTime() / 1000).toString();

    let hitStr =
      'https://www.google-analytics.com/g/collect?v=2&_et=100&_s=1&seg=0&tid=' + this.measurementId;
    hitStr += '&en=session_start';
    hitStr += '&cid=' + this.eventParams.client_id;
    hitStr += '&sid=' + this.param.sessionId;
    if (this.eventParams.user_id !== '') {
      hitStr += '&uid=' + this.eventParams.user_id;
    }
    hitStr += '&sr=' + window.screen.availWidth + 'x' + window.screen.availHeight;
    hitStr += '&ul=' + this.getNavigatorLanguage().toLowerCase();
    hitStr += '&dl=' + encodeURIComponent(document.location.href);
    hitStr += '&dt=' + encodeURIComponent(document.title);
    hitStr += '&_dbg=' + this.debugMode;

    if (document.title === 'GA4-Tester') {
      hitStr += '&dr=';
    } else if (this.inIframe()) {
      hitStr += '&dr=iframe';
    } else {
      hitStr += '&dr=' + encodeURIComponent(document.referrer);
    }

    const hit2Str = this.addUserParametersToURL(hitStr);

    fetch(hit2Str, { method: 'POST' });
  }

  private addUserParametersToURL(hitStr: string): string {
    Object.entries(this.eventParams.user_properties).forEach(([key, value]: [string, string]) => {
      hitStr += '&up.' + key + '=' + encodeURIComponent(value);
    });

    return hitStr;
  }

  private computeDt(): number {
    /* compute time since last event */
    let dt = Date.now() - this.lastTime;
    this.lastTime = Date.now();

    /* if greater than 5 mins from last event, set to 100 */
    if (dt > 5 * 60 * 1000) dt = 100;

    return dt;
  }

  private sendEvent(eventName: string, eventObject: IEventObject) {
    const dt = this.computeDt();

    let hitStr =
      'https://www.google-analytics.com/g/collect?v=2&_s=1&seg=0&tid=' + this.measurementId;
    hitStr += '&en=' + eventName;
    hitStr += '&cid=' + this.eventParams.client_id;
    hitStr += '&sid=' + this.param.sessionId;
    if (this.eventParams.user_id !== '') {
      hitStr += '&uid=' + this.eventParams.user_id;
    }

    /* copy event properties to the ep.* parameters */
    Object.entries(eventObject).forEach(([key, value]) => {
      hitStr += '&ep.' + key + '=' + encodeURIComponent(value);
    });

    hitStr += '&sr=' + window.screen.availWidth + 'x' + window.screen.availHeight;
    hitStr += '&_et=' + dt.toString();
    // hitStr += '&dt=' + encodeURIComponent(document.title);
    hitStr += '&ul=' + this.getNavigatorLanguage().toLowerCase();
    hitStr += '&_dbg=' + this.debugMode;

    const hit2Str = this.addUserParametersToURL(hitStr);
    this.sendQueueItemsURL.push(hit2Str);
  }

  public sendPageview(pageTitle: string) {
    const dt = 100;

    let hitStr =
      'https://www.google-analytics.com/g/collect?v=2&_s=1&seg=0&tid=' + this.measurementId;
    hitStr += '&en=page_view';
    hitStr += '&cid=' + this.eventParams.client_id;
    hitStr += '&sid=' + this.param.sessionId;
    if (this.eventParams.user_id !== '') {
      hitStr += '&uid=' + this.eventParams.user_id;
    }
    //hitStr +=  "&sr="+window.screen.availWidth+"x"+window.screen.availHeight;
    hitStr += '&ep.page_title=' + pageTitle;
    hitStr += '&_et=' + dt.toString();
    hitStr += '&ul=' + this.getNavigatorLanguage().toLowerCase();
    hitStr += '&_dbg=' + this.debugMode;
    const hit2Str = this.addUserParametersToURL(hitStr);
    this.sendQueueItemsURL.push(hit2Str);
  }

  // public sendTiming(
  //   label: string,
  //   categoryAsFeature: string,
  //   valueAsTime: number,
  //   nameAsValue: string
  // ) {
  //   let dt = this.computeDt();

  //   let hitStr =
  //     'https://www.google-analytics.com/g/collect?v=2&_s=1&seg=0&tid=' + this.measurementId;
  //   hitStr += '&en=timing_complete&cid=' + this.eventParams.client_id;
  //   hitStr += '&sid=' + this.param.sessionId;
  //   if (this.eventParams.user_id !== '') {
  //     hitStr += '&uid=' + this.eventParams.user_id;
  //   }
  //   hitStr += '&ep.event_label=' + label;
  //   hitStr += '&ep.event_category=' + categoryAsFeature;
  //   hitStr += '&epn.value=' + valueAsTime;
  //   hitStr += '&ep.name=' + nameAsValue;
  //   hitStr += '&_et=' + valueAsTime;
  //   hitStr += '&ul=' + this.getNavigatorLanguage().toLowerCase();
  //   hitStr += '&_dbg=' + (this.debugMode === true ? '1' : '0');

  //   let hit2Str = this.addUserParametersToURL(hitStr);
  //   this.sendQueueItemsURL.push(hit2Str);
  // }

  private getNavigatorLanguage(): string {
    return navigator.language || navigator.languages[0] || 'en';
  }

  private inIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  private getTimerValue(timer: number) {
    const time_ms = Math.min(Date.now() - timer, 1_800_000);

    let time_format: string;
    if (time_ms < 1000) {
      time_format = time_ms + 'ms';
    } else {
      const total_seconds = Math.floor(time_ms / 1000);
      const total_minutes = Math.floor(total_seconds / 60);
      const total_hours = Math.floor(total_minutes / 60);

      const days = Math.floor(total_hours / 24);
      const seconds = total_seconds % 60;
      const minutes = total_minutes % 60;
      const hours = total_hours % 24;
      time_format = `${days ? days + 'd ' : ''}${hours ? hours + 'h ' : ''}${
        minutes ? minutes + 'm ' : ''
      }${seconds ? seconds + 's ' : ''}`;
    }

    return {
      time_ms,
      time_format,
    };
  }
}

export const gaFunction = new gaFunctions();

type AppID = 'fp' | 'sp' | 'vm' | 'ki';
type ModuleID = 'fp' | 'sp' | 'vm' | 'ki' | 'pb' | 'na' | 'ds';
interface IUserProperties {
  client_division?: string;
  client_id?: string;
  client_name?: string;
  community_id?: string;
  community_name?: string;
  scene_title?:string;
  gav?: string;
  plan_name?: string;
  app_id?: AppID;
  app_env?: string;
}

interface IEventParams {
  user_id: string;
  client_id: string;
  non_personalized_ads: boolean;
  user_properties: IUserProperties;
}

interface IParams {
  hitString: string;
  userId: string;
  sessionId: string;
}

interface IEventObject {
  module_id: ModuleID;
  label: string;
  feature: string;
  value?: string | number;
  category?: string;
  time_format?: string;
  time_ms?: number;
  lot_title?: string;
  event_plan_name?: string;
  action?: string;
}

interface PrintStartParams {
  fp: {
    layout: string;
    dimension: boolean;
  };
  sp: {
    includeLotData: boolean;
  };
}
